import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./index.module.css"
import cx from "classnames"
import { i18n } from "../translate/i18n"
import NoBoring from "../assets/svg/no_boring_with_smile.svg"
import Smile from "../assets/svg/smile.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cases from "../components/home/cases"
import WindowActions from "../components/window_actions"
import Tags from "../components/tags"
import Grid from "../components/grid"
import Slider from "../components/Slider/slider"

const Home = ({ data, location }) => {
  const [delivery, setDelivery] = React.useState(1)

  const deliveryText = [
    <>{i18n.t("messages.msgDiscover")}</>,
    <>{i18n.t("messages.msgDesign")}</>,
    <>{i18n.t("messages.msgDevelop")}</>,
    <>{i18n.t("messages.msgDeliver")}</>,
  ]

  return (
    <>
      <Layout
        location={location}
        headerHome={true}
        title={data.site.siteMetadata?.title || `Title`}
      >
        <Seo title="Null Bug" />
        <Grid>
          <section className={`${styles.home_section} ${styles.md}`}>
            <NoBoring className={styles.no_boring_svg} />
          </section>
          <section className={styles.home_section}>
            <h2 className={styles.home_section_title}>
              {i18n.t("titles.tExpertise")}
            </h2>

            <p>{i18n.t("messages.msgExpertise")}</p>

            <h3
              className={`${styles.home_section_sub_title} ${styles.font_coding}`}
            >
              {i18n.t("titles.tOurClients")}
            </h3>

            <div className={styles.home_section_clients}>
              <StaticImage
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/isohub-icon.png"
                width={157}
                height={92}
                alt="Logo da Isohub"
                placeholder="blurred"
                className={styles.client_icons}
              />

              <StaticImage
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/scseg-icon.png"
                width={140}
                height={95}
                alt="Logo da SCSEG"
                placeholder="blurred"
                className={styles.client_icons}
              />

              <StaticImage
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/bierheld-icon.png"
                width={102}
                height={102}
                alt="Logo da SCSEG"
                placeholder="blurred"
                className={styles.client_icons}
              />

              <StaticImage
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/kitt-icon.png"
                width={104}
                height={92}
                alt="Logo da SCSEG"
                placeholder="blurred"
                className={styles.client_icons}
              />

              <StaticImage
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/menu46-icon.png"
                width={297}
                height={94}
                alt="Logo da SCSEG"
                placeholder="blurred"
                className={styles.client_icons}
              />
            </div>
          </section>

          <section
            className={`${styles.home_section} ${styles.home_section_full}`}
          >
            <WindowActions />
            <h2
              className={`${styles.home_section_title} ${styles.text_center} ${styles.font_quick}`}
            >
              {i18n.t("titles.tCases")}
            </h2>

            <p className={`${styles.text_center} ${styles.font_heading}`}>
              {i18n.t("messages.msgCases")}
            </p>
          </section>
          <Cases />
          <section
            className={`${styles.home_section} ${styles.home_section_full}`}
          >
            <WindowActions />

            <h3
              className={`${styles.home_section_sub_title} ${styles.text_center} ${styles.font_coding}`}
            >
              {i18n.t("titles.tTechnologies")}
            </h3>

            <Slider />
          </section>
          <section className={styles.home_section}>
            <h2 className={styles.home_section_title}>
              {i18n.t("titles.tMoreThanCode")}
            </h2>

            <button
              className={cx(styles.tag, {
                [styles.tag_active]: delivery === 1,
              })}
              onClick={() => setDelivery(1)}
            >
              01 {i18n.t("tags.tagDiscover")}
            </button>
            <button
              className={cx(styles.tag, {
                [styles.tag_active]: delivery === 2,
              })}
              onClick={() => setDelivery(2)}
            >
              02 {i18n.t("tags.tagDraw")}
            </button>
            <button
              className={cx(styles.tag, {
                [styles.tag_active]: delivery === 3,
              })}
              onClick={() => setDelivery(3)}
            >
              03 {i18n.t("tags.tagDevelop")}
            </button>
            <button
              className={cx(styles.tag, {
                [styles.tag_active]: delivery === 4,
              })}
              onClick={() => setDelivery(4)}
            >
              04 {i18n.t("tags.tagDeliver")}
            </button>

            <p className={styles.section_description}>
              &gt; {deliveryText[delivery - 1]} _
            </p>
          </section>
          <section className={styles.home_section}>
            <h3>{i18n.t("titles.tEndToEnd")}</h3>
            <Smile className={styles.smile_svg} />
          </section>

          {/* ABOUT */}
          <section className={styles.home_section}>
            <h2 className={styles.home_section_title}>
              {i18n.t("titles.tAbout")}
            </h2>
          </section>
          <section className={styles.home_section}>
            <p className={styles.section_description}>
              {i18n.t("messages.msgAbout1")}
            </p>
            <p className={styles.section_description}>
              {i18n.t("messages.msgAbout2")}
            </p>
          </section>

          {/* TIME */}
          <section className={styles.home_section_full}>
            <h2 className={styles.home_section_title}>
              {i18n.t("titles.tTechPeoples")}
            </h2>
            <Tags
              items={[
                i18n.t("tags.tagTechnology"),
                i18n.t("tags.tagDesign"),
                i18n.t("tags.tagBusiness"),
                i18n.t("tags.tagUx"),
                i18n.t("tags.tagProgramming"),
              ]}
            />
            <p className={styles.section_description}>
              {i18n.t("messages.msgTechPeoples")}
            </p>
          </section>
        </Grid>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

export default Home
