// extracted by mini-css-extract-plugin
export var image_case = "cases-module--image_case--1UmY1";
export var image = "cases-module--image--1AQ8q";
export var case_description = "cases-module--case_description--3APzb";
export var case_description_right = "cases-module--case_description_right--3DXmY";
export var case_number = "cases-module--case_number--L8b-S";
export var logo = "cases-module--logo--193A8";
export var title = "cases-module--title--3VfSb";
export var description = "cases-module--description--TLe_U";
export var link = "cases-module--link--35cTt";
export var case_container = "cases-module--case_container--3BeBr";