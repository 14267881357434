import * as React from "react"

import * as styles from './tags.module.css'

const Tags = ({items}) => {

  return (
    <div style={{marginTop: '25px'}}>
      {items?.map((tag) => {
        return (<div className={styles.tags} key={tag}>{tag}</div>)
      })}
    </div>
  )
}

export default Tags
