import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import WindowActions from "../window_actions"
import Tags from "../tags"

import * as styles from "./cases.module.css"
import { i18n } from "../../translate/i18n"

const Cases = () => {
  return (
    <>
      <div className={styles.case_container}>
        {/* ISOHUB */}
        <section className={styles.image_case}>
          <StaticImage
            src="../../images/home/isohub-nullbug-case.png"
            alt="Nullbug case"
            className={styles.image}
          />
        </section>
        <section className={styles.case_description}>
          <WindowActions />

          <span className={styles.case_number}>01</span>

          <StaticImage
            src="../../images/isohub-logo-nullbug.png"
            alt="Nullbug logo"
            className={styles.logo}
          />
          <Tags
            items={[
              i18n.t("tags.tagDesign"),
              "Front-end",
              "Back-end",
              i18n.t("tags.tagFace"),
            ]}
          />

          <h3 className={styles.title}> &lt; Isohub &gt;</h3>
          <p className={styles.description}> {i18n.t("messages.msgIsohub")}</p>
        </section>
      </div>

      <div className={styles.case_container}>
        {/* SCSEG */}
        <section
          className={`${styles.case_description} ${styles.case_description_right}`}
        >
          <WindowActions />

          <span className={styles.case_number}>02</span>

          <StaticImage
            src="../../images/scseg-icon.png"
            alt="SCSeg icone"
            className={styles.logo}
          />
          <Tags
            items={[
              i18n.t("tags.tagDesign"),
              "Front-end",
              "Back-end",
              i18n.t("tags.tagFace"),
            ]}
          />

          <h3 className={styles.title}> &lt; SCSeg &gt;</h3>
          <p className={styles.description}>{i18n.t("messages.msgSCSeg")}</p>
        </section>
        <section className={styles.image_case}>
          <StaticImage
            src="../../images/home/scseg-nullbug-case.png"
            alt="SCSeg Case"
            className={styles.image}
          />
        </section>
      </div>

      <div className={styles.case_container}>
        {/* Kitt */}
        <section className={styles.image_case}>
          <StaticImage
            src="../../images/home/kitt-nullbug-case.png"
            alt="Kitt case"
            className={styles.image}
          />
        </section>
        <section className={styles.case_description}>
          <WindowActions />

          <span className={styles.case_number}>03</span>

          <StaticImage
            src="../../images/kitt-icon.png"
            alt="Kitt logo"
            className={styles.logo}
          />
          <Tags items={["Design", "Front-end", "Back-end"]} />

          <h3 className={styles.title}> &lt; Kitt &gt;</h3>
          <p className={styles.description}>{i18n.t("messages.msgKitt")}</p>
        </section>
      </div>

      <div className={styles.case_container}>
        {/* Bierheld */}
        <section
          className={`${styles.case_description} ${styles.case_description_right}`}
        >
          <WindowActions />

          <span className={styles.case_number}>04</span>

          <StaticImage
            src="../../images/bierheld-icon.png"
            alt="Bierheld icone"
            className={styles.logo}
          />
          <Tags items={["Design", "Front-end"]} />

          <h3 className={styles.title}> &lt; BierHeld &gt;</h3>
          <p className={styles.description}>{i18n.t("messages.msgBierHeld")}</p>
        </section>
        <section className={styles.image_case}>
          <StaticImage
            src="../../images/home/bierheld-nullbug-case.png"
            alt="Bierheld case"
            className={styles.image}
          />
        </section>
      </div>
    </>
  )
}

export default Cases
