// extracted by mini-css-extract-plugin
export var home_section = "index-module--home_section--13C2t";
export var home_section_title = "index-module--home_section_title--1lZtI";
export var home_section_sub_title = "index-module--home_section_sub_title--2SHlg";
export var home_section_clients = "index-module--home_section_clients--L2rmO";
export var images_team = "index-module--images_team--3i3jg";
export var input_field = "index-module--input_field--rKGRh";
export var submit_button = "index-module--submit_button--2jIFV";
export var md = "index-module--md--1T7Jv";
export var expertise_no_boring_image = "index-module--expertise_no_boring_image--2QAk3";
export var expertise_smile_image = "index-module--expertise_smile_image--qIwVw";
export var tech_image = "index-module--tech_image--3rCc7";
export var no_boring_svg = "index-module--no_boring_svg--3PbyM";
export var smile_svg = "index-module--smile_svg--1gVqd";
export var font_coding = "index-module--font_coding--1FqDd";
export var text_center = "index-module--text_center--Rs95G";
export var font_quick = "index-module--font_quick--2Gbbj";
export var font_heading = "index-module--font_heading--37Lls";
export var tag = "index-module--tag--HB8uS";
export var tag_active = "index-module--tag_active--fe5FR";
export var section_description = "index-module--section_description--1S2JC";
export var image_team = "index-module--image_team--2MVvj";
export var client_icons = "index-module--client_icons--36stL";
export var home_section_full_team = "index-module--home_section_full_team--3ztu8";
export var home_section_full_images = "index-module--home_section_full_images--17ylK";
export var home_section_full = "index-module--home_section_full--1osna";