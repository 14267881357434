import React, { Component } from "react";
import Slider from "react-slick";

import RubyImage from "../../images/home/technologies/ruby.png"
import NodeImage from "../../images/home/technologies/node.png"
import FlutterImage from "../../images/home/technologies/flutter.png"
import ReactImage from "../../images/home/technologies/react.png"
import XDImage from "../../images/home/technologies/xd.png"
import * as styles from "../../pages/index.module.css"

export default class Responsive extends Component {

  render() {
    var settings = {
      dots: false,
      arrows: false,
      autoplay: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src={FlutterImage} className={styles.tech_image} alt="Flutter" width="259" height="106" />
          </div>
          <div>
            <img src={RubyImage} className={styles.tech_image} alt="Ruby" width="72" height="86" />
          </div>
          <div>
            <img src={ReactImage} className={styles.tech_image} alt="React" width="96" height="106" />
          </div>
          <div>
            <img src={NodeImage} className={styles.tech_image} alt="Node" width="248" height="106" />
          </div>
          <div>
            <img src={XDImage} className={styles.tech_image} alt="Adobe XD" width="99" height="106" />
          </div>

        </Slider>
      </div>
    );
  }
}