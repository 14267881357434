import * as React from "react"

import * as styles from './window.module.css'

const WindowActions = () => {

  return (
    <div>
      <div className={styles.actions_circle}></div>
      <div className={styles.actions_circle}></div>
      <div className={styles.actions_circle}></div>
    </div>
  )
}

export default WindowActions
